<template>
    <div class="resume">
        <div class="title">
            <div class="sub-title">{{year}}年{{month}}月 - 数据上报</div>
            <div class="sub-filtter"><el-button @click="openadd" icon="el-icon-plus" size="small" :disabled="nowyear!=year || nowmonth!=month">添加记录</el-button></div>
        </div>
        <el-card class="box-card" style="min-height:100%;padding-bottom:20px; ">
            <div class="list" v-loading="loading">
                <el-table
                :data="list"
                :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="positionTitle"
                    label="岗位名称">
                    </el-table-column>
                    <el-table-column
                    prop="demandNumber"
                    align="center"
                    label="需求人数">
                    </el-table-column>
                    <el-table-column
                    prop="jobNumber"
                    align="center"
                    label="求职人数">
                    </el-table-column>
                    <el-table-column
                    prop="employmentRatio"
                    align="center"
                    label="供需比">
                    </el-table-column>
                    <el-table-column
                    prop="id"
                    width="150"
                    v-if="nowyear==year && nowmonth==month"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" @click="openedit(scope.row)">编辑</el-button>
                            <el-button size="mini" @click="deleteitem(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <pop-up :title="status==1?'添加记录':'编辑记录'" width="30%" :isshow="showpop" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="岗位名称">
                        <el-input v-model="form.positionTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="需求人数">
                        <el-input-number v-model="form.demandNumber" :min="0" label="需求人数"></el-input-number>
                    </el-form-item>
                    <el-form-item label="求职人数">
                        <el-input-number v-model="form.jobNumber" :min="0" label="求职人数"></el-input-number>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import companyRequest from '../../../api/company'
export default {
    data(){
        return{
            status:1,
            nowyear:"",
            nowmonth:"",
            loading:false,
            year:"",
            month:"",
            user:null,
            list:[],
            showpop:false,
            form:{id:null,month:null,companyId:null,positionTitle:null,demandNumber:null,jobNumber:null}
        }
    },
    created(){
        var date = new Date();
        this.nowyear=date.getFullYear();
        let zsm=date.getMonth()+1;
        this.nowmonth=zsm
        this.year=this.$route.query.y
        this.month=this.$route.query.m
        this.user=JSON.parse(localStorage.getItem('userInfo'))
        this.form.companyId=this.user.id
        this.form.month=this.month
        this.getList()
    },
    methods:{
        deleteitem(row){
            companyRequest.delData({id:row.id}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("删除成功！")
                    this.getList()
                }else{
                    this.$message.error("删除失败！")
                }
            })
        },
        openedit(row){
            for(let k in this.form){
                this.form[k]=row[k]
            }
            this.status=2
            this.showpop=true
        },
        openadd(){
            this.form={id:null,month:this.month,companyId:this.user.id,positionTitle:null,demandNumber:null,jobNumber:null}
            this.status=1
            this.showpop=true
        },
        getList(){
            this.loading=true
            companyRequest.queryData({year:this.year,companyId:this.user.id,month:this.month}).then(res=>{
                console.log(res)
                this.loading=false
                this.list=res.data
            })
        },
        getclose(){
            this.form={id:null,month:this.month,companyId:this.user.id,positionTitle:null,demandNumber:null,jobNumber:null}
            this.showpop=false
        },
        getconfirm(){
            if(this.status==1){
                companyRequest.insertData(this.form).then(res=>{
                    if(res.success){
                        this.$message.success("添加成功！")
                        this.getList()
                        this.getclose()
                    }
                })
            }else{
                companyRequest.editData(this.form).then(res=>{
                    if(res.success){
                        this.$message.success("编辑成功！")
                        this.getList()
                        this.getclose()
                    }
                })
            }
        }

    }
}
</script>
<style scoped>

</style>